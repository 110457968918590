<template>
  <div id="scroll-to" class="why-fitmark">
    <!-- <div style="clip-path: url(#explainer-1); width: 662px; height: 337px; background: red;"></div> -->
    <!-- <Explainer1Clip />
    <Explainer2Clip />
    <Explainer3Clip />
    <Explainer4Clip /> -->

    <div
      v-for="(item, index) in items"
      :key="item.id"
      class="explainer"
      :class="{
        'explainer--one': index === 0,
        'explainer--two': index === 1,
        'explainer--three': index === 2,
        'explainer--four': index === 3,
        'explainer--five': index === 4,
      }"
    >
      <div class="explainer__flex">
        <div class="explainer__copy">
          <h2 class="explainer__title">{{ item.title }}</h2>
          <span v-html="item.content" />
        </div>
        <IllustrationWeightTraining
          v-if="index === 0"
          class="explainer__illustration"
        />
        <IllustrationFood v-if="index === 1" class="explainer__illustration" />
        <IllustrationNatuur
          v-if="index === 2"
          class="explainer__illustration"
        />
        <IllustrationRelaxation
          v-if="index === 3"
          class="explainer__illustration"
        />
        <IllustrationFun v-if="index === 4" class="explainer__illustration" />
      </div>
    </div>
  </div>
</template>

<script>
// import Explainer1Clip from '../components/Icons/Box1'
// import Explainer2Clip from '../components/Icons/Box2'
// import Explainer3Clip from '../components/Icons/Box3'
// import Explainer4Clip from '../components/Icons/Box4'

import IllustrationFun from "./Icons/Untitled-2.vue";
import IllustrationFood from "./Icons/Food.vue";
import IllustrationRelaxation from "./Icons/Relaxation.vue";
import IllustrationNatuur from "./Icons/Untitled-3.vue";
import IllustrationWeightTraining from "./Icons/Untitled-5.vue";

export default {
  name: "Explainer",
  components: {
    // Explainer1Clip,
    // Explainer2Clip,
    // Explainer3Clip,
    // Explainer4Clip,
    IllustrationFun,
    IllustrationNatuur,
    IllustrationWeightTraining,
    IllustrationFood,
    IllustrationRelaxation,
  },
  props: {
    items: Array,
  },
  data: function () {
    return {};
  },
};
</script>
