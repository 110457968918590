<template>
  <div>
    <main>
      <Article>
        <template v-slot:swirl>
          <swirl :pageId="12" />
        </template>

        <template v-slot:article__header>
          <ArticleHeader
            :heading="`${gql.data.page.title}`"
            :copy="`${gql.data.page.acf.subtitle}`"
          />
        </template>

        <template v-slot:article__image>
          <Visual>
            <img
              width="640"
              height="480"
              :src="gql.data.page.featuredImage.node.sourceUrl"
              :alt="gql.data.page.featuredImage.node.altText"
            />
          </Visual>
        </template>

        <template v-slot:content__copy>
          <copy v-html="gql.data.page.content" />
        </template>

        <template v-slot:content__scroll>
          <scroll-to :anchor="'#scroll-to'">
            {{ gql.data.page.acf.scrollto }}
          </scroll-to>
        </template>
      </Article>
      <explainer :items="gql.data.pijlers.nodes" />
    </main>
    <Footer>
      <template v-slot:links>
        <links :links="links" />
      </template>
    </Footer>
  </div>
</template>

<script>
import Article from "../components/Article.vue";
import ArticleHeader from "../components/ArticleHeader.vue";
import Visual from "../components/ArticleImage.vue";
import Explainer from "../components/Explainer.vue";
import Copy from "../components/ContentCopy.vue";
import Links from "../components/Links.vue";
import Footer from "../components/Footer.vue";
import ScrollTo from "../components/ContentScroll.vue";
import Swirl from "../components/Swirl.vue";

export default {
  name: "WhyFitmark",
  components: {
    Article,
    ArticleHeader,
    Visual,
    Explainer,
    Copy,
    Footer,
    Links,
    ScrollTo,
    Swirl,
  },
  data: function () {
    return {
      links: [
        {
          name: "personalTraining",
          copyText: "Ik wil meer weten over de personal training",
          ctaText: "Ga naar Personal training",
        },
      ],
      overridingStyles: {
        background: "linear-gradient(to right, #2DA6A7 0%, #D1E1BE 100%)",
        // background: 'linear-gradient(to right, #509e6c 0%, #D1E1BE 100%)'
      },
      gql: {
        data: {
          page: {
            id: "cG9zdDoxMg==",
            content:
              "\n<h2>Waarom Fitmark?</h2>\n\n\n\n<p>Fitmark is een fitnessbedrijf in Haarlem en verzorgd personal training, golfsurflessen en sportmassages (de surflessen zijn alleen te volgen als onderdeel van de personal training).</p>\n\n\n\n<p>Bij Fitmark geloven we dat we samen de experts zijn; jij van jouw leven en lichaam en wij van de trainingstechnieken. Samen zoeken we naar de best passende manier om jouw doelen te bereiken. Hierbij staat een balans tussen inspanning en ontspanning centraal. Dit uit zich bij ons in vijf elementen die samen de perfecte trainingsformule vormen.</p>\n",
            title: "Fitmark: Personal Training & Sportmassage",
            featuredImage: {
              node: {
                altText: "",
                sourceUrl:
                  "https://content.fitmark.nl/wp-content/uploads/2021/05/Nummer-3-1024x675.jpg",
                __typename: "MediaItem",
              },
              __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
            },
            acf: {
              subtitle: "Fitmark wil samen met jou in beweging blijven",
              scrollto: "Bekijk de elementen van Fitmark",
              __typename: "Page_Acf",
            },
            __typename: "Page",
          },
          pijlers: {
            nodes: [
              {
                id: "cG9zdDo2MzY=",
                title: "1. Beweging",
                content:
                  "\n<p>Met beweging bedoelen we niet simpelweg een rondje fietsen of wandelen, maar functionele fitness. Het heeft een krachtig effect op je dagelijks functioneren en is een uitstekend middel om je beweegkwaliteit te onderhouden. Daarom staat functionele fitness centraal tijdens de personal trainingen van Fitmark. Je gaat aan de slag met basis oefeningen, zoals de squat en de deadlift en dat allemaal op technisch verantwoorde wijze. Op de personal training pagina lees je hier meer informatie over.</p>\n",
                __typename: "Pijler",
              },
              {
                id: "cG9zdDo1MTQ=",
                title: "2. Voeding",
                content:
                  "\n<p>Ook voeding is een belangrijk onderdeel van ons fitnessprogramma. Middels een voedingsplan proberen we samen ervoor te zorgen dat je, indien nodig, gezond(er) gaat eten. Onder gezonde voeding verstaan wij groente, kruiden, fruit, peulvruchten, noten, zaden, vis, volkoren granen en een beperkte hoeveelheid zuivelproducten. We vermijden liever rood- en bewerkt vlees, alcoholische dranken en teveel suiker, zout en verzadigde vetzuren. Mark zelf gaat nog een stap verder en verbouwd zijn eigen groentes en kruiden. Hierover lees je meer informatie op de moestuin pagina.</p>\n",
                __typename: "Pijler",
              },
              {
                id: "cG9zdDo5OA==",
                title: "3. Natuur",
                content:
                  "\n<p>Bij Fitmark rennen we graag door het bos en surfen we op de golven van de Noordzee. Verschillende studies wijzen uit dat we ons krachtiger en positiever voelen als we sporten in de natuur. Het heeft een reducerend effect op de hoeveelheid cortisol in ons lichaam en geeft ons zowel fysieke als mentale ontspanning. Daarom is sporten in de natuur extra gezond!</p>\n",
                __typename: "Pijler",
              },
              {
                id: "cG9zdDo5Nw==",
                title: "4. Ontspanning",
                content:
                  "\n<p>Het is niet altijd even makkelijk om balans te vinden tussen inspanning en ontspanning. Een ontspannende massage op zijn tijd kan daarbij helpen. Fitmark biedt sport- en ontspanningsmassages aan. Het doel van een sportmassages is puur ter ontspanning en herstel van spieren en een ontspanningsmassage voor ons algehele welbevinden. Op de massagepagina vind je meer informatie over ons aanbod.</p>\n",
                __typename: "Pijler",
              },
              {
                id: "cG9zdDo5Ng==",
                title: "5. Plezier",
                content:
                  "\n<p>Fitmark vindt het belangrijk dat je doet wat je leuk vindt. Dit noemen we ‘spelen’. Hieronder verstaan we het doen van een activiteit zonder specifiek doel, maar wel met plezier en betrokkenheid. Voor ons komt dit terug in het golfsurfen. Een watersport zoals golfsurfen dwingt ons in het moment te zijn en het in contact zijn met de elementen van de zee geeft ons plezier.</p>\n",
                __typename: "Pijler",
              },
            ],
            __typename: "RootQueryToPijlerConnection",
          },
        },
      },
    };
  },
};
</script>
