<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 500 465.64"
  >
    <path
      fill="#f68c3c"
      d="M249.44 102.36c-44.99-23.78-100.9-1.45-121.95 75.36-8.84 31.81-8.51 76.64-3.85 110.23.32 1.6 2.57 1.45 2.57-.16.16-17.03 2.41-34.39 7.07-51.42 9.16-32.78 25.71-57.84 45.31-72.15l-8.03 7.23c-.32.32-.49.64-.49 1.13 2.57 29.25 9.32 75.68 7.07 123.24-3.22 66.52-17.67 135.29-31.81 161.96-.64.96.64 2.25 1.77 1.77 43.39-21.05 68.13-91.91 71.18-178.19 8.03 27.32 12.05 66.36 9.16 100.9-1.93 24.58-6.91 57.68-13.82 78.25-.16.8.8 1.29.96.64 49.33-64.91 50.3-113.44 51.1-169.2.96-57.04-9.64-98.98-17.19-129.83-.32-1.29 1.13-2.09 1.93-1.29 24.9 18.64 43.39 90.3 49.49 129.35.16.49.8.49.8 0 6.41-63.78 1.76-159.7-51.27-187.82z"
    />
    <path
      fill="#010101"
      d="M301.5 182.05c-8.36-42.1-25.23-70.06-50.13-83.4-21.69-11.56-45.63-12.69-67.17-3.38-28.28 12.21-49.97 40.97-60.9 81.31-9.64 34.54-8.2 81.95-3.85 112.15.49 2.89 2.89 4.98 5.94 4.82 3.05-.32 5.14-2.57 5.14-5.47.16-17.19 2.41-34.06 6.91-50.3 6.27-22.65 16.39-42.1 29.08-56.4 2.09 31.97 8.36 80.98 5.62 129.35-3.53 60.9-17.83 120.83-30.69 145.09-1.13 2.09-.64 4.66.96 6.43 1.6 1.6 4.34 2.25 6.43 1.13 39.05-29.25 63.79-73.91 71.66-151.2 3.38 22.5 4.5 47.72 2.73 69.57-1.93 23.94-6.75 57.04-13.65 77.29-.8 2.25.16 4.82 2.25 5.94.8.32 1.45.64 2.25.64 1.45 0 3.05-.64 4.02-1.93 50.3-66.36 51.1-115.05 52.06-171.76.8-51.42-7.71-90.78-14.78-120.5 19.6 24.58 35.03 84.04 40.65 119.54.49 2.25 2.57 4.02 4.82 4.02 2.41-.16 4.18-1.93 4.66-4.34 1.77-22.47 4.34-66.66-4.01-108.6zm-2.9 78.42c-9.32-40.33-25.54-87.73-45.48-102.83-1.93-1.45-4.5-1.45-6.43-.32-1.93 1.29-2.89 3.69-2.41 5.94l.64 2.25c7.4 30.05 17.51 71.18 16.55 126.3-.8 50.3-1.6 94.16-38.56 150.07 4.34-19.76 7.4-42.74 8.67-59.46 2.73-33.9-.96-73.11-9.32-102.36-.64-1.93-2.57-3.38-4.66-3.05-2.09.32-3.69 2.09-3.69 4.18-3.05 84.04-18.48 130.31-61.06 170 11.56-19.92 24.74-94.16 27.16-129.02 3.69-53.99-2.57-111.99-5.47-148.47l7.07-6.27c1.6-1.45 1.93-4.02.49-5.78-1.45-1.77-4.02-2.25-5.78-.64-21.21 15.43-37.92 41.94-46.92 74.55-1.77 6.11-3.05 12.38-4.18 18.64-.96-24.9.49-52.7 6.58-75.04 10.45-37.76 30.37-64.75 56.24-75.84 19.12-8.2 40.33-7.23 59.77 3.05 40.51 21.53 52.88 89.35 50.79 154.1z"
    />
    <path
      fill="#f68c3c"
      d="M244.14 79.38c10.76.16 21.37-4.34 28.6-12.53 6.75-7.4 10.12-17.03 9.48-27.16-.64-9.96-4.98-19.28-12.53-26.03-6.75-5.94-15.43-9.48-24.43-9.64-10.93-.16-21.37 4.34-28.6 12.53-13.82 15.43-12.38 39.21 3.05 53.02 6.91 6.12 15.59 9.65 24.43 9.81z"
    />
    <path
      fill="#010101"
      d="M286.55 39.54c-.64-11.09-5.47-21.53-13.82-28.76C265.35 4.02 255.54.33 245.58 0c-12.21-.16-23.78 4.82-31.81 13.98-15.27 17.19-13.82 43.7 3.38 59.13 7.55 6.75 17.19 10.45 27.16 10.76 12.21.16 23.78-4.82 31.81-13.98 7.22-8.34 11.08-19.1 10.43-30.35zm-17.03 24.41c-6.43 7.07-15.74 11.25-25.39 11.09-8.03-.16-15.58-3.05-21.69-8.51-13.65-12.05-14.78-33.26-2.73-47.08 6.43-7.07 15.74-11.25 25.39-11.09 8.03.16 15.58 3.05 21.69 8.51 6.75 5.94 10.6 13.98 11.09 22.98.64 9.01-2.41 17.52-8.36 24.1z"
    />
    <path
      fill="#f68c3c"
      stroke="#010101"
      stroke-miterlimit="10"
      stroke-width="5"
      d="M300.69 226.08c-7.87-.8-15.74-1.29-23.78-1.45-37.59-.8-97.53-2.25-146.86 0-14.78.64-77.6 1.6-90.3 6.75-14.14 10.6-14.94 26.03-14.94 26.03S7.46 271.39 9.87 282.64c3.53 6.11 40.81 3.05 54.47 2.89 44.5-.49 250.34-.64 310.75-1.13 12.21-.16 22.81-4.66 26.51-11.09 2.73-4.82-.32-11.25-2.73-14.14-2.09-2.57-4.98-4.34-7.87-6.11l-11.09-6.58c-11.73-5.94-22.5-8.84-30.85-11.25-11.89-3.21-29.41-7.22-48.37-9.15z"
    />
    <path
      fill="none"
      stroke="#010101"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="5"
      d="m348.57 258.38-22.18-2.09c-10.12-.96-20.25-.96-30.21 0l-25.71 2.89"
    />
    <path
      fill="#fff"
      d="M482.43 322.81H17.42c-8.2 0-14.94-3.85-14.94-8.51v-20.56c0-4.66 6.75-8.51 14.94-8.51h465.17c8.2 0 14.94 3.85 14.94 8.51v20.56c-.16 4.65-6.92 8.51-15.1 8.51z"
    />
    <path
      fill="#010101"
      d="M482.43 325.28H17.42C7.65 325.28 0 320.45 0 314.29v-20.56c0-6.16 7.65-10.99 17.42-10.99h465.17c9.77 0 17.42 4.83 17.42 10.99v20.56c-.22 6.2-7.94 10.99-17.58 10.99zM17.42 287.69c-7.13 0-12.47 3.19-12.47 6.04v20.56c0 2.85 5.33 6.04 12.47 6.04h465.01c7.11 0 12.53-3.23 12.63-6.12v-20.48c0-2.85-5.33-6.04-12.47-6.04H17.42z"
    />
    <path
      fill="none"
      stroke="#010101"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="5"
      d="M270.49 259.18c-4.98 6.75-6.91 15.27-5.31 23.46"
    />
    <path
      fill="#f68c3c"
      d="M448.84 278.14c10.93 0 21.21-4.66 28.44-13.02 6.58-7.55 9.8-17.19 9-27.32-.8-9.96-5.31-19.12-13.02-25.87-6.75-5.94-15.58-9.16-24.58-9.16-10.93 0-21.21 4.66-28.44 13.02-13.5 15.74-11.73 39.52 3.85 53.02 7.08 5.96 15.91 9.33 24.75 9.33z"
    />
    <path
      fill="#010101"
      d="M490.61 237.65c-.8-11.09-5.94-21.37-14.3-28.6a41.958 41.958 0 0 0-27.48-10.29c-12.21 0-23.62 5.31-31.65 14.47-15.1 17.51-13.18 44.03 4.34 59.13 7.71 6.58 17.36 10.29 27.48 10.29 12.21 0 23.62-5.31 31.65-14.47 7.23-8.51 10.93-19.44 9.96-30.53zm-16.54 24.75c-6.27 7.23-15.58 11.41-25.23 11.41-8.03 0-15.74-2.89-21.85-8.2-13.82-11.89-15.43-33.1-3.53-47.08 6.27-7.23 15.58-11.41 25.23-11.41 8.03 0 15.74 2.89 21.85 8.2 6.75 5.94 10.93 13.82 11.41 22.81.79 9-2.1 17.52-7.88 24.27z"
    />
    <path
      fill="none"
      stroke="#010101"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="5"
      d="m158.5 255.16-38.41-2.41a407.57 407.57 0 0 0-52.54 0l-39.05 2.41"
    />
    <path
      fill="#fff"
      stroke="#010101"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="5"
      d="M230 337.76h-65.4c-10.6 0-19.28-8.67-19.28-19.28v-85.16c0-10.6 8.67-19.28 19.28-19.28H230c10.6 0 19.28 8.67 19.28 19.28v85.16c0 10.59-8.68 19.28-19.28 19.28z"
    />
    <path
      fill="#fff"
      stroke="#010101"
      stroke-miterlimit="10"
      stroke-width="5"
      d="M399.99 324.58h36.32v137.06h-36.32V324.58zm-334.37 0h36.32v137.06H65.62V324.58z"
    />
  </svg>
</template>

<script>
export default {
  name: "IllustrationRelaxation",
};
</script>
