<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 500 611.16"
  >
    <path
      fill="#feba3e"
      d="M233.29 226.55c10.91 0 21.31-4.61 28.64-12.96 6.65-7.5 9.89-17.21 9.04-27.27-.68-10.06-5.29-19.26-12.96-25.91-6.82-5.97-15.51-9.2-24.55-9.2-11.08 0-21.31 4.61-28.64 12.96-13.64 15.69-11.76 39.55 3.92 53.19 6.64 5.96 15.33 9.19 24.55 9.19z"
    />
    <path
      d="M233.29 230.82c-10.06 0-19.78-3.58-27.45-10.23-17.56-15.17-19.43-41.6-4.43-59.15 8.01-9.2 19.43-14.49 31.71-14.49 10.06 0 19.78 3.58 27.45 10.23 8.52 7.33 13.64 17.56 14.32 28.64.68 11.25-2.73 21.99-10.06 30.52-7.85 9.19-19.44 14.48-31.54 14.48zm-.18-75.35c-9.72 0-18.92 4.26-25.23 11.42-11.94 13.98-10.4 35.12 3.41 47.22 5.97 5.29 13.98 8.18 21.99 8.18 9.72 0 18.92-4.26 25.23-11.42 5.97-6.65 8.69-15.35 8.01-24.21s-4.61-17.05-11.42-22.84a33.094 33.094 0 0 0-21.99-8.35z"
    />
    <circle cx="327.22" cy="43.98" r="30.68" fill="#f88c3a" />
    <path
      d="M327.22 78.93c-19.27 0-34.95-15.68-34.95-34.95s15.68-34.95 34.95-34.95 34.95 15.68 34.95 34.95-15.69 34.95-34.95 34.95zm0-61.38c-14.57 0-26.43 11.85-26.43 26.43s11.85 26.43 26.43 26.43c14.57 0 26.43-11.85 26.43-26.43s-11.87-26.43-26.43-26.43z"
    />
    <path
      fill="#b65534"
      d="M146.85 21.82c-.85-3.92.17-8.01 2.73-11.42.34-.51.68-.85 1.2-1.36 2.9-2.39 8.01-4.26 11.76-4.43 6.47-.17 11.08 2.73 14.49 7.5 3.24 4.26 1.88 4.26 3.07 4.26 1.02 0 1.36 0 4.61-4.61 3.58-4.77 9.54-7.5 15.85-7.5 5.11.34 9.54 2.73 12.44 6.31 5.11 6.82 1.02 15.69-1.02 20.12-1.02 2.39-11.6 24.72-31.54 25.91-21.83 1.19-32.57-23.36-33.59-34.78z"
    />
    <path
      d="M178.91 60.9c-10.29 0-19.41-4.96-26.48-14.41-6.32-8.46-9.21-18.16-9.8-24.04-.99-4.93.27-10.21 3.47-14.52.55-.8 1.08-1.34 1.64-1.89.1-.1.22-.2.32-.29 3.54-2.91 9.54-5.19 14.28-5.4 7.52-.2 13.61 2.93 18.15 9.28.09.12.17.22.26.34.14-.2.3-.42.46-.66 4.4-5.86 11.6-9.3 19.34-9.3.09 0 .19 0 .28.01 6.11.41 11.75 3.28 15.47 7.88 6.74 8.98 1.69 19.91-.46 24.58-.42 1-11.83 26.98-35.15 28.39-.6.01-1.19.03-1.78.03zm-25.28-48.69c-.32.32-.41.43-.5.55-.04.07-.09.13-.14.2-1.85 2.47-2.55 5.3-1.98 7.96.04.17.07.35.08.52.46 5.1 3.15 13.25 8.16 19.95 5.76 7.71 12.82 11.39 20.94 10.96 18.11-1.09 27.75-23.12 27.85-23.33 1.82-3.95 5.12-11.1 1.53-15.88-2.11-2.61-5.49-4.32-9.18-4.6-5.01.04-9.6 2.2-12.3 5.79-2.93 4.17-4.44 6.31-8.01 6.31-2.29 0-3.88-1.05-4.69-3.13-.11-.27-.4-1-1.77-2.81-2.95-4.13-6.39-5.92-10.99-5.82-2.73.11-6.84 1.63-9 3.33z"
    />
    <path
      d="M180.78 16.19c-.07 0-.13 0-.2-.01a3.396 3.396 0 0 1-3.2-3.6l.51-8.86a3.415 3.415 0 0 1 3.6-3.2c1.88.11 3.32 1.72 3.2 3.6l-.51 8.86c-.1 1.8-1.59 3.21-3.4 3.21zM327.04 15h-.12a3.416 3.416 0 0 1-3.3-3.52l.17-5.11c.07-1.88 1.68-3.34 3.52-3.3 1.88.06 3.36 1.64 3.3 3.52l-.17 5.11a3.396 3.396 0 0 1-3.4 3.3z"
    />
    <path
      fill="#feba3e"
      d="M394.72 146.27c-16.71 48.75-55.92 89.16-109.1 105.01C198 277.53 110.2 226.39 79.35 140.3c-.68-1.54-1.2-3.24-1.7-4.77-1.54 19.6 1.02 39.72 8.01 59.49 17.73 49.61 55.58 87.28 101.09 103.48-9.38 16.03-13.47 29.83-17.21 50.8 0 0-1.36 9.04-3.92 27.27 12.96.17 37.17 1.02 50.12 1.54 10.74.51 21.48.51 32.22.34h2.22c3.75-30 10.91-61.2 13.81-73.47 5.97-1.02 11.94-2.56 18.07-4.26 69.7-20.8 114.2-85.25 112.66-154.45z"
    />
    <path
      d="M400.17 146.61c0-2.73-2.04-4.77-4.26-5.11-2.39-.34-4.61 1.02-5.45 3.24-16.71 48.92-56.43 86.94-105.87 101.77-40.57 12.1-83.36 7.5-120.52-12.96-36.48-20.29-64.95-54.04-79.44-94.95-.68-1.54-1.2-3.24-1.54-4.77-.68-2.39-2.9-3.75-5.29-3.41-2.39.34-4.09 2.22-4.26 4.61-1.54 20.8 1.2 41.6 8.52 61.54 17.39 48.93 53.53 86.94 99.56 104.84-6.65 15.35-11.6 29.66-14.66 45.68 0 0-1.02 4.95-1.02 5.11-.85 3.75-2.04 8.86-3.75 24.04 3.24 0 5.63.17 8.86.17 2.39-16.37 3.92-23.86 3.92-24.21 1.02-5.29 1.88-8.01 1.7-8.18 3.58-16.87 9.38-28.29 15.35-43.82.51-1.2.51-2.73-.17-3.75-.51-1.36-1.54-2.04-2.9-2.73-45.18-16.19-81.15-52.85-98.2-100.41-3.58-10.23-6.13-20.29-7.16-30.86 16.71 34.1 42.96 62.05 75.52 79.95 39.55 21.82 85.07 26.77 128.37 13.81 43.13-12.96 79.27-42.62 100.58-81.49-9.2 56.42-50.12 104.33-106.37 121.2-5.63 1.7-11.6 3.07-17.56 4.09-1.88.34-3.07 2.04-4.09 3.75-7.16 13.64-5.45 45.86-7.16 74.84.85 0-5.29 0-4.26-.17h10.23c.85-27.11 4.43-54.21 7.84-68.36 5.29-1.02 12.96-2.9 17.9-4.43 69.56-20.95 117.29-86.41 115.58-159.03z"
    />
    <path
      fill="#feba3e"
      d="M258.51 337.03c-28.13-.17-56.42-.51-84.55-.85-6.31 26.77-11.94 59.49-11.94 100.24 0 55.92.17 104.5 48.59 170.65.34.68 1.2.17 1.02-.68-6.47-20.63-11.08-54.04-12.62-78.76-2.22-34.78 2.39-73.81 10.91-101.09.34 14.49 1.2 28.47 2.73 41.94.68 6.65 2.22 9.04 3.75 15.35 3.24 13.3 6.47 20.12 9.54 31.2 1.7 5.63 8.69 17.05 11.42 21.82 17.05 28.47 33.92 33.08 55.58 43.98.68.17 8.01 1.88 15.35 3.24 7.16 1.54 14.15 3.07 13.81 2.39-1.54-3.24-3.58-5.45-6.47-7.84s-6.47-4.95-10.74-8.86c-3.75-3.41-22.34-20.12-26.77-23.7-20.8-16.37-21.99-80.46-21.14-129.22 0-3.75 0-7.33-.17-10.91-.17-25.6.51-49.47 1.7-68.9z"
    />
    <path
      d="M262.78 329.7c-2.73 0-5.45 1.02-8.35 1.02-1.2 18.41-2.73 41.94-3.58 66.82-.17 6.31 0 13.13-.51 18.75-6.82 82.34 22.5 128.37 24.21 131.1 10.23 17.21 29.32 30.34 29.32 30.34-1.02 1.02-11.42-1.88-15.17-3.41-2.39-.85-10.91-4.95-12.44-5.79-9.2-5.11-13.81-9.38-25.91-22.84-3.75-4.26-9.89-14.32-12.96-19.43-3.92-6.47-16.53-31.54-19.78-52.68-.51-3.75-.85-7.5-1.2-11.42-1.2-11.59-1.88-23.86-2.04-36.48 0-2.04-1.54-3.92-3.58-4.26-2.04-.34-4.09 1.02-4.61 3.07-8.86 29.32-13.13 68.53-11.08 102.63 1.02 16.71 3.58 39.89 7.67 59.83-36.31-56.6-36.31-100.75-36.31-151.21 0-40.57 6.99-83.53 15.35-109.96-3.07 0-7.67-5.29-7.67-5.29-6.47 13.47-16.53 74.84-16.53 115.41 0 56.77 0 105.69 49.43 173.2.85 1.2 2.39 2.04 3.92 2.04.68 0 1.54-.34 2.22-.68 2.04-1.02 3.24-3.58 2.39-5.97-6.47-20.46-10.91-53.87-12.44-77.74-1.36-21.99.17-47.22 3.92-69.89 6.47 77.74 53.02 121.88 94.44 129.73 0 0 9.38 2.39 18.24 3.24 2.56.17 5.29-1.54 4.09-5.45 0 0 0-1.54-6.82-6.31-1.7-1.2-5.63-3.24-11.25-7.67s-12.96-11.25-21.31-21.48c-1.54-1.88-30.68-43.64-26.25-130.92.34-5.97.51-15 .68-20.8.84-23.35 2.55-45.68 3.91-67.5z"
    />
    <path
      fill="#9ac667"
      d="M106.62 140.81c-1.36 22.84-24.72 35.63-39.89 30.86S38.44 142 51.9 123.59c6.65-9.2 11.94-11.94 18.07-16.53 10.57-8.35 14.66-21.31 23.18-18.58 10.91 3.41 6.82 10.57 8.01 26.43.35 7.49 5.8 19.42 5.46 25.9z"
    />
    <path
      d="M73.46 176.91c-2.8 0-5.5-.39-8.02-1.18-9.39-2.95-17.83-12.1-21.51-23.31-3.68-11.2-2.07-22.34 4.52-31.35 5.8-8.03 10.81-11.6 15.67-15.05 1.08-.76 2.17-1.54 3.29-2.38 3.49-2.76 6.38-6.34 8.94-9.5 4.91-6.07 9.98-12.33 18.1-9.74 11.01 3.44 10.9 10.95 10.77 19.65-.05 3.05-.1 6.49.2 10.52.15 3.2 1.41 7.57 2.63 11.79 1.57 5.43 3.05 10.55 2.83 14.66v.03c-.7 11.67-6.76 22.26-16.62 29.06-6.44 4.44-13.88 6.8-20.8 6.8zm17.53-84.52c-2.03 0-4.1 2.27-8.01 7.11-2.84 3.5-6.05 7.48-10.37 10.89-1.26.95-2.42 1.77-3.54 2.57-4.68 3.33-8.72 6.2-13.71 13.11-6.66 9.11-4.94 18.74-3.32 23.68 2.81 8.54 9.22 15.71 15.97 17.84 6.29 1.98 14.5.26 21.41-4.5 4.52-3.11 12.21-10.18 12.96-22.53.13-2.68-1.21-7.33-2.51-11.84-1.36-4.71-2.76-9.57-2.95-13.65-.32-4.29-.27-8.1-.22-11.17.14-8.71-.02-9.89-4.81-11.38-.31-.08-.61-.13-.9-.13z"
    />
    <path
      d="M93.5 95.64c-.35 0-.7-.05-1.05-.17a3.424 3.424 0 0 1-2.21-4.29l2.9-9.04c.58-1.79 2.5-2.78 4.29-2.21a3.42 3.42 0 0 1 2.21 4.29l-2.9 9.04a3.413 3.413 0 0 1-3.24 2.38z"
    />
    <path
      fill="#feba3e"
      d="M390.63 133.31c29.15-8.69 48.41-48.41 58.3-35.63 12.96 16.71-7.84 53.36-51.65 66.32-43.82 12.96-74.16-11.94-71.09-23.02 3.41-12.1 35.12 1.02 64.44-7.67z"
    />
    <path
      d="M372.87 171.74c-16.23 0-32.77-4.44-44.11-15.58-5.59-5.5-8.03-11.45-6.68-16.33 2.71-9.62 14.62-8.94 28.39-8.14 11.41.66 25.61 1.48 38.93-2.47 13.1-3.91 24.5-15 33.66-23.91 8.86-8.62 15.3-14.87 21.96-14.38 1.96.14 4.81.97 7.26 4.14 5.04 6.49 6.07 15.49 2.9 25.33-5.08 15.76-22.98 37.71-56.71 47.68-7.81 2.32-16.66 3.66-25.6 3.66zm-33.35-31.95c-4.96 0-8.71.51-9.22 2.35-.31 1.12.61 4.18 4.45 7.95 8.13 7.98 30.18 19.03 61.33 9.82 28.58-8.45 46.22-27.25 51.01-42.12 2.28-7.08 1.74-13.29-1.52-17.5-.64-.83-.99-.85-1.12-.86-.04 0-.08-.01-.13-.01-2.95 0-9.79 6.65-15.3 12-9.84 9.56-22.07 21.46-37.17 25.97h-.01c-14.76 4.37-29.78 3.51-41.85 2.81-3.62-.2-7.29-.41-10.47-.41z"
    />
    <path
      d="M445.52 96.15c-1.88 0-3.41-1.53-3.4-3.42 0-.65.19-1.29.54-1.84l5.79-9.04a3.401 3.401 0 0 1 4.71-1.03h.01a3.403 3.403 0 0 1 1.03 4.71l-5.79 9.04c-.64.99-1.73 1.58-2.89 1.58zm-79.27 426.01c-32.9-6.13-56.6-35.8-55.4-69.21 0-.85.17-1.7.17-2.73.17-2.56-1.7-4.95-4.43-5.11-2.56-.17-4.95 1.7-5.11 4.43 0 1.02-.17 1.88-.17 3.07-1.2 33.58 19.78 64.1 50.63 75.69a75.4 75.4 0 0 0 12.96 3.58c2.56.34 5.11-1.2 5.63-3.92.15-2.73-1.72-5.29-4.28-5.8z"
    />
    <path
      d="M365.63 532.78c-.28 0-.56-.02-.85-.06-4.63-.85-8.91-2.04-13.15-3.63-31.81-11.95-52.38-42.7-51.19-76.52 0-.61.05-1.17.09-1.7.04-.45.07-.87.07-1.34.09-1.54.76-2.9 1.88-3.88 1.14-1 2.64-1.51 4.15-1.4 1.49.09 2.84.76 3.82 1.87 1 1.14 1.51 2.64 1.4 4.15 0 .51-.05 1.02-.09 1.51-.03.4-.07.78-.07 1.16-1.18 32.96 22.34 62.34 54.7 68.37h.01c2.97.59 5.15 3.53 4.95 6.68-.52 2.89-2.91 4.79-5.72 4.79zm-59.36-86.83c-.97 0-1.93.36-2.65 1-.77.68-1.23 1.62-1.29 2.65 0 .46-.04.95-.08 1.44-.04.48-.08.99-.08 1.58-1.15 32.59 19.44 63.38 50.08 74.89a74.54 74.54 0 0 0 12.82 3.54c2.22.28 4.23-1.1 4.63-3.24.13-2.22-1.44-4.37-3.59-4.8-33.18-6.19-57.29-36.32-56.09-70.08 0-.41.04-.84.08-1.29.04-.44.08-.9.08-1.4.07-1.11-.28-2.17-.99-2.97a3.806 3.806 0 0 0-2.65-1.29c-.1-.03-.18-.03-.27-.03z"
    />
    <path
      d="M349.38 499.49c.34.34.68.51 1.02.51 2.04.85 4.26.17 5.63-1.7 1.54-2.22 1.02-5.29-1.2-6.82-14.66-10.4-24.89-26.59-27.95-44.32-.51-2.73-2.9-4.43-5.63-3.92s-4.43 2.9-3.92 5.63c3.58 20.44 15.17 38.68 32.05 50.62z"
    />
    <path
      d="M352.16 501.21a5.3 5.3 0 0 1-1.94-.37c-.48-.05-.95-.28-1.39-.7-16.97-12.02-28.76-30.66-32.34-51.14-.59-3.16 1.39-6.01 4.6-6.61 3.18-.61 6.02 1.39 6.62 4.61 3.04 17.53 13.1 33.49 27.61 43.78 2.58 1.78 3.21 5.38 1.4 8-1.13 1.57-2.83 2.43-4.56 2.43zm-29.95-57.22c-.27 0-.53.02-.81.07-2.3.43-3.66 2.38-3.24 4.63 3.51 20.07 15.06 38.33 31.71 50.09l.11.09c.17.17.32.27.42.27l.33.07c1.66.7 3.48.14 4.61-1.42 1.27-1.83.83-4.36-.99-5.62-14.89-10.56-25.21-26.92-28.31-44.88-.38-2-1.93-3.3-3.83-3.3zm-3.35 145.68c-50.97-1.36-85.07-40.57-98.7-86.77-1.54-3.41-1.02-2.39-2.56-5.79-1.36-3.41-3.41-8.01-4.77-11.25-1.02-5.63-3.24-15-3.75-21.14-1.7-18.41-3.75-37.17-1.54-58.3.17-1.2.34-2.56.51-3.58 0-.34.17-.85.17-1.2.17-2.22 2.22-3.75 4.61-3.58 2.39.17 4.09 2.04 3.75 4.43 0 .34-.17 1.02-.17 1.36-.17 1.2-.34 2.04-.34 2.9-.68 14.66-.85 28.64-.51 41.77.17 6.65.51 12.96 1.02 19.09 1.36 8.18 3.24 16.03 5.79 23.7 1.2 3.92 2.73 7.67 4.09 11.42 14.15 44.66 53.36 73.65 97.68 85.58 0 0-1.7 1.7-2.04 1.2-.68.16-1.87.16-3.24.16z"
    />
  </svg>
</template>

<script>
export default {
  name: "IllustrationFood",
};
</script>
